.management-company-container__accordion {
  flex: 1 1;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 2px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

.management-company-container__item-button:focus {
  outline: none;
}

.management-company-container__item + .management-company-container__item {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  margin-top: 5px;
}

.management-company-container__item-panel {
  padding-top: 5px;
}

.management-company-container__accordion--empty {
  flex: 1;
  text-align: center;
  color: var(--main-font-color);
  margin-top: 10px;
}

.management-company-container__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.management-company-container__spinner {
  font-size: 30px;
  color: var(--main-font-color-w);
}

.management-company-container__spinner--small {
  font-size: 17px;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
