.management {
  display: flex;
  align-self: flex-start;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 660px;
  min-height: 500px;
  max-height: 650px;
}

.management__info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px 10px 5px;
}

.management__report-range {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px 5px;
}

.management__text-button {
  position: relative;
  padding: 2px 12px;
  background: var(--main-fg-color);
  color: var(--main-font-color);
  font-size: 10px;
  border-radius: 6px;
  font-weight: 100;
  outline: none;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.management__action-button {
  width: 18px;
  height: 18px;
  font-size: 11px;
  border: 1px solid var(--main-font-color);
  border-radius: 4px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-bg-color);
  outline: none;
  cursor: pointer;
  margin: 2px 0px 2px 9px;
}

.management__title,
.management__credit {
  display: flex;
  font-size: 25px;
  font-weight: 100;
  color: var(--main-ac-color);
  align-items: center;
}

.management__date-range {
  display: flex;
  font-size: 11px;
  align-items: center;
  padding: 1px 7px;
}

.management__date-label {
  padding: 0 5px;
  color: var(--main-font-color);
}

.management__date-input {
  font-family: Roboto;
  font-size: 14px;
  background-color: var(--main-fg-color);
  caret-color: var(--main-font-color);
  border: 1px solid var(--main-fg-color);
  color: var(--main-font-color);
  border-radius: 4px;
  padding: 2px 4px;
  width: 94px;
  outline: none;
}

.management__title {
  flex: 1 1;
}

.management__credit {
  flex: 0;
}

.management__table {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

.management__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 5px;
  color: var(--main-font-color);
  font-weight: 300;
}

.management__search:-webkit-autofill,
.management__search:-webkit-autofill:hover, 
.management__search:-webkit-autofill:focus, 
.management__search:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px var(--main-fg-color) inset !important;
  box-shadow: 0 0 0 30px var(--main-fg-color) inset !important;
  -webkit-text-fill-color: var(--main-font-color-w) !important;
}

.management__search::placeholder { /* Most modern browsers support this now. */
  color: var(--main-font-color);
  font-weight: 300;
}
.management__search,
.management__search:-webkit-autofill {
  font-family: Roboto;
  font-size: 14px;
  background-color: var(--main-fg-color);
  caret-color: var(--main-font-color);
  border: 1px solid var(--main-fg-color);
  color: var(--main-font-color);
  border-radius: 4px;
  padding: 2px 4px;
  flex: 1 1;
  outline: none;
}

.management__content {
  display: flex;
  flex: 1 1;
  background-color: var(--main-fg-color);
  border-radius: 7px;
  padding: 5px;
  align-items: flex-start;
  overflow-y: auto;
  min-height: 500px;
  max-height: 500px;
}
/* 
.management__content .management__team {
  flex:1;
  background-color: var(--main-fg-color);
  outline: none;
  border: none;
  border-radius: 10px;
}

.management__user {
  outline: none;
  border-radius: 10px;
  border:none;
  background-color: var(--main-bg-color);
  margin-top: 4px;
} */


.management__accordion {
  flex: 1 1;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 2px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

.management__accordion--empty {
  flex: 1;
  text-align: center;
  color: var(--main-font-color);
  margin-top: 10px;
}

.management__item {
  flex: 1;
}

.management__item + .management__item {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  margin-top: 5px;
}

.management__item-panel {
  padding-left: 60px;
  padding-top: 5px;
}

.management__item-button {
  outline: none;
}

.management__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.management__spinner {
  font-size: 30px;
  color: var(--main-font-color-w);
}

.management__spinner--small {
  font-size: 17px;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}