.upload-form {
  display: flex;
  align-self: center;
  width: 500px;
  justify-content: center;
}

.upload {
  display: flex;
  flex-flow: column wrap;
  min-width: 360px;
  align-content: center;
}

.upload__dropzone {
  height: 11em;
}

.upload__filelist {
  font-family: 'Roboto';
}

.upload__select .upload-select__control {
  background-color: var(--main-fg-color);
  border-color: var(--main-font-color);
}
.upload__select .upload-select__input,
.upload__select .upload-select__single-value {
  caret-color: var(--main-font-color);
  color: var(--main-font-color);
}
.upload__select .upload-select__indicators {
  padding-right: 42px;
}

.upload__select .upload-select__dropdown-indicator:hover {
  border-color: var(--main-font-color);
}

.upload__select .upload-select__indicators svg{
  height: 36px;
  width: 42px;
  position: absolute;
  top: 0;
  right: -2px;
}

.upload__select .upload-select__indicators .upload-select__indicator-separator{
  visibility: hidden;
}

.upload__select .upload-select__menu {
  background-color: var(--main-bg-color);
  color: var(--main-font-color);
}

.upload__select .upload-select__option--is-focused {
  background-color: var(--main-fg-color);
  color: var(--main-font-color);
}

.upload__select .upload-select__option--is-selected {
  background-color: var(--main-font-color);
  color: var(--main-fg-color);
}

.upload__button {
  padding: 1px 15px;
  flex:1;
  background: var(--main-fg-color);
  font-size: 24px;
  color: var(--main-font-color);
  border-radius: 6px;
  font-weight: 100;
  outline: none;
}

.upload__select,
.upload__button 
{
  width: 205px;
  margin: 10px auto;
  outline: none;
  border-color: var(--main-font-color);
}

