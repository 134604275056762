.download {
  display: flex;
  flex-flow: row nowrap;
}
.download__spinner {
  z-index: 1;
  position: absolute;
}

.download__select .download-select__control {
  background-color: var(--main-fg-color);
  border-color: var(--main-font-color);
}
.download__select .download-select__input,
.download__select .download-select__single-value {
  caret-color: var(--main-font-color);
  color: var(--main-font-color);
}

.download__select .download-select__dropdown-indicator:hover {
  border-color: var(--main-font-color);
  color: var(--hover-fg-color);
}

.download__select .download-select__indicators .download-select__indicator-separator{
  visibility: hidden;
}

.download__select .download-select__menu {
  background-color: var(--main-fg-color);
  color: var(--main-font-color);
}

.download__select .download-select__option--is-focused {
  background-color: var(--hover-fg-color);
  color: var(--main-font-color);
}

.download__select .download-select__option--is-selected {
  background-color: var(--main-font-color);
  color: var(--main-fg-color);
}