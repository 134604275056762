.client-management {
  display: flex;
  align-self: flex-start;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 660px;
  min-height: 500px;
  max-height: 650px;
}

.client-management__info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px 10px 5px;
}

.client-management__action-button {
  width: 18px;
  height: 18px;
  font-size: 11px;
  border: 1px solid var(--main-font-color);
  border-radius: 4px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-bg-color);
  outline: none;
  cursor: pointer;
  margin: 2px 0px 2px 9px;
}

.client-management__title,
.client-management__credit {
  display: flex;
  font-size: 25px;
  font-weight: 100;
  color: var(--main-ac-color);
  align-items: center;
}

.client-management__credit {
  justify-content: right;
}

.client-management__table {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

.client-management__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 5px;
  color: var(--main-font-color);
  font-weight: 300;
}

.client-management__search:-webkit-autofill,
.client-management__search:-webkit-autofill:hover, 
.client-management__search:-webkit-autofill:focus, 
.client-management__search:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px var(--main-fg-color) inset !important;
  box-shadow: 0 0 0 30px var(--main-fg-color) inset !important;
  -webkit-text-fill-color: var(--main-font-color-w) !important;
}

.client-management__search::placeholder { /* Most modern browsers support this now. */
  color: var(--main-font-color);
  font-weight: 300;
}
.client-management__search,
.client-management__search:-webkit-autofill {
  font-family: Roboto;
  font-size: 14px;
  background-color: var(--main-fg-color);
  caret-color: var(--main-font-color);
  border: 1px solid var(--main-fg-color);
  color: var(--main-font-color);
  border-radius: 4px;
  padding: 2px 4px;
  flex: 1 1;
  outline: none;
}

.client-management__content {
  display: flex;
  flex: 1 1;
  background-color: var(--main-fg-color);
  border-radius: 7px;
  padding: 7px 15px;
  align-items: flex-start;
  min-height: 500px;
  max-height: 500px;
}
/* 
.client-management__content .user-management__team {
  flex:1;
  background-color: var(--main-fg-color);
  outline: none;
  border: none;
  border-radius: 10px;
}

.client-management__user {
  outline: none;
  border-radius: 10px;
  border:none;
  background-color: var(--main-bg-color);
  margin-top: 4px;
} */

.client-management__list {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

.client-management__row {
  display: flex;
  flex-flow: row nowrap;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
}

.client-management__row--header {
  font-weight: 300;
  font-size: 11px;
}

.client-management__row--empty {
  font-weight: 300;
  font-size: 13px;
  align-self: center;
}

.client-management__row:nth-child(even) {
  background: var(--main-bg-color);
  color: var(--main-font-color);
}

.client-management__row:nth-child(odd) {
  background-color: var(--main-fg-color);
  color: var(--main-font-color);
}

.client-management__field--name{
  flex: 2;
}

.client-management__field--code{
  flex: 1;
}

.client-management__field--action{
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.client-management__field--creditType{
  flex: 1;
}

.client-management__field--credit {
  flex: 1;
}

.client-management__field--processed {
  flex: 1;
}

.client-management__field--emphasize {
  color: var(--main-ac-color);
}

.client-management__button--icon {
  background-color: transparent;
  color: var(--main-font-color);
  font-size: 14px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.client-management__accordion {
  flex: 1;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 2px;
  display: flex;
  justify-content: center;
}

.client-management__item + .user-management__item {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  margin-top: 5px;
}

.client-management__item-panel {
  animation: fadeIn 0.38s ease-in;
}

.client-management__item-button {
  outline: none;
}

.client-management__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.client-management__spinner {
  font-size: 30px;
  color: var(--main-font-color-w);
}

.client-management__spinner--small {
  font-size: 15px;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}