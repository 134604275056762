.edit-button {
  color: var(--main-font-color);
  background-color: var(--main-fg-color);
  font-size: 19px;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  cursor: pointer;
}