.project-item {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-radius: 10px;
  font-weight: 300; 
}

.project-item:nth-child(odd) {
  background: var(--main-bg-color);
  color: var(--main-font-color);
}
.project-item:nth-child(even) {
  background-color: var(--main-fg-color);
  color: var(--main-font-color);
}

.project-item__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  font-size: 14px;
}

.project-item__thumbnail {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 107px;
}

.project-item__spinner {
  z-index: 1;
  position: absolute;
}

.animate-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}

@-webkit-keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)}
  to{
    -webkit-transform:rotate(359deg);
    transform:rotate(359deg)
  }
}
@keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  to{
    -webkit-transform:rotate(359deg);
    transform:rotate(359deg)
  }
}

.animate-fadein {
  -webkit-animation: fadeIn 500ms ease-in;
  animation: fadeIn 500ms ease-in;
}

@-webkit-keyframes fadeIn{
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.project-item__img,
.project-item__object 
{
  z-index: 2;
  height: 60px;
}

.project-item__title {
  margin-left: 25px;
  margin-right: 20px;
  flex: 1;
  text-align: left;
  word-break: break-all;
}

.project-item__date {
  padding: 0 11px;
}

.project-item__action {
  display: flex;
  flex-flow: row nowrap;
  width: 118px;
  justify-content: space-evenly;
}