.user-table {
  display: flex;
  flex-flow: column nowrap;
}

.user-table__row {
  display: flex;
  flex-flow: row nowrap;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
}

.user-table__row--header {
  font-weight: 300;
  font-size: 11px;
}

.user-table__row--empty {
  font-weight: 300;
  font-size: 13px;
  align-self: center;
}

.user-table__row:nth-child(even) {
  background: var(--main-bg-color);
  color: var(--main-font-color);
}
.user-table__row:nth-child(odd) {
  background-color: var(--main-fg-color);
  color: var(--main-font-color);
}

.user-table__username {
  flex: 1;
}

.user-table__role {
  flex: 1;
}
/* .user-table__password {
  display: flex;
  flex: 2;
  justify-content: center;
} */
.user-table__type {
  flex: 1;
}
/* .user-table__access {
  display: flex;
  flex: 2;
  justify-content: center;
} */
.user-table__minutes {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

/* .user-table__delete {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
} */

.user-table__action {
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.user-table__button--icon {
  background-color: transparent;
  color: var(--main-font-color);
  font-size: 14px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}