.confirm-modal__overlay {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  bottom: 0;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  padding: 47px;
}

.confirm-modal {
  display: flex;
  font-family: Roboto;
  flex-flow: column nowrap;
  background-color: var(--main-bg-color);
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
}

.confirm-modal__header {
  display: flex;
  color: var(--main-ac-color);
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: default;
  padding: 5px;
  /* margin: 2px 0 5px; */
  text-align: center;
  border: none;
  border-radius: 5px;
  outline: none;
}

.confirm-modal__title {
  flex: 1;
}

.confirm-modal__action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.confirm-modal__button {
  display: flex;
  width: 23px;
  height: 23px;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 100;
  border: none;
  color: var(--main-font-color-w);
  background: var(--main-fg-color);
  outline: none;
  cursor: pointer;
  margin: 9px;
  align-items: center;
  justify-content: center;
}

.confirm-modal__content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1;
  color: var(--main-font-color);
  font-weight: 300;
  font-size: 17px;
  letter-spacing: 1px;
}

.confirm-modal__form {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.confirm-modal__submit-button {
  position: relative;
  padding: 2px 15px;
  background: var(--main-fg-color);
  color: var(--main-font-color);
  font-size: 14px;
  border-radius: 6px;
  font-weight: 100;
  outline: none;
  right: 0;
  cursor: pointer;
}