.project-list {
  display: flex;
  justify-items: stretch;
  flex-flow: column;
  flex: 1;
}
.project-list__label {
  font-weight: 300;
  font-size: 32px;
  padding: 10px 20px;
  color: var(--main-ac-color);
}
.project-list__fields {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 300;
  color: var(--main-font-color);
}
.project-list__left {
  cursor: pointer;
}
.project-list__right {
  cursor: pointer;
}
.project-list__items {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  border: 1px solid var(--main-font-color);
  border-radius: 6px;
  outline: none;
  padding: 15px 10px;
  overflow: auto;
  background-color: var(--main-fg-color);
  text-align: center;
  color: var(--main-font-color-w);
  min-height: 500px;
  max-height: 500px;
}

.project-list__icon {
  border: 1px solid;
  border-radius: 5px;
  color: var(--main-fg-color);
  border-color: var(--main-fg-color);
}

.project-list__icon--active {
  color: var(--main-font-color);
  border-color: var(--main-font-color);
}