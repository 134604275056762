.transcript {
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  border: 1px solid var(--main-font-color);
  border-radius: 5px;
  padding: 0 15px;
  height: 100%;
  align-items: baseline;
  align-content: flex-start;
  background-color: var(--main-fg-color);
}

.transcript__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.transcript__spinner {
  font-size: 30px;
  color: var(--main-font-color-w);
}
