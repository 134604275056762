.menu {
  display: flex;
  justify-content: space-between;
  flex: 1 1 100%;
  position: absolute;
  font-family: 'Roboto', sans-serif;
  align-self: flex-start;
  height: 76px;
  padding: 0 65px;
  background-color: #010101;
  color: var(--main-font-color);
  top: 0;
  width: 100%;
}

.menu__down-icon {
  font-size: 16px;
  position: absolute;
  left: -17px;
  top: 43px;
  color: white;
}

.menu--hidden {
  display: none;
}

.menu__left {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  margin: 10px;
  align-items: flex-end;
  position: relative;
}

.menu__nav {
  
}

.menu__nav .dropdown {
  display: inline-block;
}

.menu__nav .dropdown__trigger {
  font-size: 23px;
  font-weight: 200;
  color: var(--main-ac-color);
  display: flex;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.menu__nav .dropdown__content {
  display: none;
  position: absolute;
  border: 1px solid #b7b7b750;
  border-radius: 3px;
  top: 61px;
  left: -10px;
  width: fit-content;
  white-space: nowrap;
  background-color: var(--main-bg-color);
  z-index: 1;
}

@media screen and (max-width: 700px) {
  .menu__nav .dropdown__content {
    top: 63px;
    left: -2px;
  }
}

.menu__nav .dropdown__content ul{
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
}

.menu__nav .dropdown__content ul li{
  padding: 4px 10px;
  cursor: pointer;
  width: 100%;
}

.menu__nav .dropdown__content ul li:hover{
  background-color: var(--main-fg-color);
}

.menu__nav .dropdown--active .dropdown__content {
  display: block;
}

.menu__nav-link:link,
.menu__nav-link:active,
.menu__nav-link:visited,
.menu__nav-link {
  font-size: 16px;
  font-weight: 300;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: start;
  height: 31px;
  width: 90px;
  color: var(--main-font-color);
}

.menu__nav-link:hover{
  text-decoration: underline;
  text-underline-position: below;
}

.menu__nav-link--disabled:hover{
  text-decoration: none;
  cursor: pointer;
}

.menu__nav-link--disabled:hover{
  text-decoration: none;
  cursor: default;
}

.menu__nav-link--disabled .icon__button {
  background-color: var(--main-font-color);
  color: var(--main-font-color-w);
}

.menu__nav-link--disabled {
  color: var(--hover-bg-color);
}

.menu__right {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  margin: 10px;
  align-items: flex-end;
  position: relative;
}

.menu__img {
  width: 60px;
  height: 60px;
  align-self: center;
}
.menu__user-info{
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  margin: 10px;
}
.menu__website{
  font-size: 22px;
  font-weight: 400;
  color: var(--main-ac-color);
}
.menu__username{
  font-size: 14px;
  font-weight: 300;
}

.menu__credit {
  font-size: 13px;
  font-weight: 500;
  color: var(--main-ac-color);
}

.menu__credit-change {
  opacity: 0;
  font-weight: 300;
  margin-top: -4px;
  margin-left: 6px;
  font-size: 19px;
  position: absolute;
}

.menu__credit-change--show {
  opacity: 1;
}

.menu__credit-change--hide {
  opacity: 0;
}

.menu__credit-change--increase {
  color: #badc58;
}

.menu__credit-change--decrease {
  color: #ff7979;
}


.menu__logout {
  font-size: 14px;
  font-weight: 300;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  color: var(--main-font-color);
}

.menu__option:link,
.menu__option:active,
.menu__option:visited,
.menu__option {
  font-size: 13px;
  font-weight: 300;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  color: var(--main-font-color);
}

.menu__option:hover{
  text-decoration: underline;
  text-underline-position: below;
}

.menu__option--disabled:hover{
  text-decoration: none;
  cursor: pointer;
}

.menu__option--disabled:hover{
  text-decoration: none;
  cursor: default;
}

.menu__option--disabled .icon__button {
  background-color: var(--main-font-color);
  color: var(--main-font-color-w);
}

.menu__option--disabled {
  color: var(--hover-bg-color);
}


.menu__icon{
  color: var(--main-font-color);
  padding: 0 6px;
  align-self: center;
  font-size: 20px;
  margin-right: 6px;
}

.menu__action .dropdown {
  display: inline-block;
}

.menu__action .dropdown__trigger {
  font-size: 23px;
  font-weight: 200;
  color: var(--main-ac-color);
  display: flex;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.menu__action .dropdown__content {
  display: none;
  position: absolute;
  border: 1px solid #b7b7b750;
  border-radius: 5px;
  top: 7px;
  left: -260px;
  text-align: center;
  padding: 4px 0px;
  width: 260px;
  white-space: nowrap;
  background-color: var(--main-bg-color);
  z-index: 1;
}

@media screen and (max-width: 700px) {
  .menu__action .dropdown__content {
    top: 68px;
    left: -134px;
  }
}

.menu__action .dropdown__content ul{
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
}

.menu__action .dropdown__content ul li{
  padding-top: 4px;
  padding-bottom: 4px;
}


.menu__action .dropdown--active .dropdown__content {
  display: block;
}

.icon__button{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 37px;
  border-radius: 5px;
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  background-color: var(--main-ac-color);
  color: var(--main-font-color-b);
}

.menu__link:link,
.menu__link:active,
.menu__link:visited,
.menu__link {
  display: flex;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
}

.menu__link:hover {
  outline: none;
  text-decoration: none;
}