.component-field {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
}

.component-field__error {
  font-size: 9px;
  padding-left: 11px;
  color: #e47d7d;
  height: 10px;
  flex-basis: 100%;
  text-align: right;
}

.component-field__error--hidden {
  display: none;
}

.component-field__label {
  padding: 0 7px;
  font-size: 14px;
  font-weight: 300;
  flex: 1 3;
  word-break: break-word;
  text-align: end;
  color: var(--main-font-color);
}

.component-field__label--radio {
  align-self: flex-start;
}

.component-field__input:not(.button) {
  font-family: Roboto;
  font-size: 14px;
  background-color: var(--main-bg-color);
  caret-color: var(--main-font-color);
  border: none;
  color: var(--main-font-color);
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 11px;
  margin: 5px 0px;
  text-align: left;
  flex: 3 1;
  outline: none;
}

.component-field__input[type=number]::-webkit-inner-spin-button, 
.component-field__input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0; 
}

.component-field__input::placeholder {
  color: var(--hover-fg-color);
  font-weight: 200;
}

.component-field__input:disabled, .component-field__input[aria-disabled=true] {
  color: var(--hover-bg-color);
  background-color: var(--main-fg-color);
  border: 1px solid var(--main-bg-color);
}
.component-field__input:disabled:hover, .component-field__input[aria-disabled=true] {
	cursor: default;
}

.component-field__submit-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  position: relative;
  padding: 2px 15px;
  background: var(--main-fg-color);
  color: var(--main-font-color);
  font-size: 14px;
  border-radius: 6px;
  font-weight: 100;
  right: 0;
  cursor: pointer;
  align-self: flex-end;
  width: 71px;
}

.component-field__submit-button:disabled {
  cursor: default;
}

.component-field__submit-button:not(:disabled):hover {
  background-color: var(--hover-fg-color);
}

.component-field__select {
	box-sizing: border-box;
	margin: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
  font-family: Roboto;
  font-size: 14px;
  caret-color: var(--main-font-color);
  color: var(--main-font-color);
  border: none;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  margin: 5px 0px;
  text-align: left;
  flex: 3 1;
  outline: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23b7b7b7%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, var(--main-bg-color) 0%,var(--main-bg-color) 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.component-field__select::-ms-expand {
	display: none;
}
.component-field__select:hover {
	cursor: pointer;
}
.component-field__select:focus {
	border-color: #aaa;
	box-shadow: none;
	outline: none;
	color: var(--main-font-color);
}

.component-field__radio-container {
  display: flex;
  align-items: left;
  justify-content: left;
  flex-flow: column nowrap;
  font-family: Roboto;
  font-size: 14px;
  border: none;
  color: var(--main-font-color);
  border-radius: 4px;
  /* padding-top: 4px; */
  padding-bottom: 4px;
  padding-right: 11px;
  margin: 0px 0px 5px;
  flex: 3 1;
  outline: none;
}

.component-field__radio {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.component-field__radio-input {

}

.component-field__radio-label {
  margin: 0 5px;
}

/* Disabled styles */
.component-field__select:disabled, .component-field__select[aria-disabled=true] {
  color: var(--hover-bg-color);
  border: 1px solid var(--main-bg-color);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%236c6f73%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, var(--main-fg-color) 0%,var(--main-fg-color) 100%);
}
.component-field__select:disabled:hover, .component-field__select[aria-disabled=true] {
	cursor: default;
}