.transcribe-kai {
  display: flex;  
  font-weight: bold;
  text-align: center;
  flex-flow: row wrap;
  flex: 0 85%;
  align-self: center;
  align-content: center;
  justify-content: space-between;
}
.transcribe-kai__video-player {
  display: flex;
  flex: 1 100%;
  align-content: stretch;
  flex-flow: row wrap;
}
.transcribe-kai__video-player .video-react-control-bar{
  font-family: 'Roboto' !important;
}

.transcribe-kai__video {
  display: flex;
  flex-flow: row wrap;
  flex: 1 100%;
  align-content: flex-start;
}

.transcribe-kai__transcript {
  position: relative;
}

.ReactModal__Body--open .transcribe-kai__transcript {
  overflow: hidden;
  padding-right: 30px;
}

.transcribe-kai__label {
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  flex: 1 1 100%;
  color: var(--main-font-color);
  margin: 10px 0;
}

.transcribe-kai__icon{
  background-color: var(--main-ac-color);
  color: var(--main-font-color-b);
  border-radius: 4px;
  margin-right: 6px;
}

.transcribe-kai__panel {
  display: flex;
  flex-flow: column nowrap;
  align-self: stretch;
  width: 100%;
  margin-top: 41px;
}

.transcribe-kai__save {
  position: relative;
  padding: 2px 15px;
  background: var(--main-fg-color);
  color: var(--main-font-color);
  font-size: 20px;
  border-radius: 6px;
  font-weight: 100;
  outline: none;
}

.transcribe-kai__save i {
  right: -30px;
}

.transcribe-kai__download {
  color: var(--main-font-color-w);
  background-color: transparent;
  font-size: 19px;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.transcribe-kai__seperator {
  flex: 1;
}

.transcribe-kai__spinner {
  z-index: 1;
  position: absolute;
}

.transcribe-kai__option {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.transcribe-kai__notif-button {
  background: transparent;
  font-size: 13px;
  border-radius: 6px;
  font-weight: 100;
  outline: none;
  cursor: pointer;
  margin-left: 18px;
  border: none;
  color: var(--main-font-color-w);
}

.transcribe-kai__notif-button--confirm {
  color: var(--main-ac-color);
}

.transcribe-kai__notif-button:hover {
  color: var(--main-font-color);
}

.transcribe-kai__notif-button--confirm:hover {
  color: var(--hover-ac-color);
}

.transcribe-kai__italic {
  font-style: italic;
}
.transcribe-kai__bold{
  font-weight: bold;
}
.transcribe-kai__add-region {
  color: var(--main-font-color);
  background-color: var(--main-fg-color);
  font-size: 19px;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  margin: 0 10px 0 0;
  cursor: pointer;
}

@media all and (min-width: 600px) {
  .transcribe-kai__panel {
    flex: 1 100px; 
  }
  .transcribe-kai__video { flex: 1 100%; }
}

@media all and (min-width: 1000px) {
  .transcribe-kai__video { flex: 2 0px; }
  .transcribe-kai__panel {
    margin-left: 30px;
  }
}

.transcribe-kai__player-container {
  flex: 0 1 100%;
  padding-bottom: 30px;
}

.transcribe-kai__player-container .video-react-control-bar {
  position: relative;
  display: flex;
}

.transcribe-kai__wave-container {
  display: flex;
  position: relative;
  font-weight: bold;
  text-align: center;
  flex-flow: column nowrap;
  flex: 0 1 100%;
  align-self: center;
  align-content: center;
  justify-content: center;
}

.transcribe-kai__waveform {
  border-radius: 9px;
}

.transcribe-kai__waveform minimap wave {
  overflow: hidden !important;
}

.transcribe-kai__waveform minimap {
  border: 1px solid var(--main-font-color) !important;
}

.wavesurfer-region {
  display: flex;
  border-radius: 1px;
  margin: auto;
  top: 0 !important;
  bottom: 0 !important;
  height: 90% !important;
  border-radius: 9px;
  z-index: 3 !important;
}

.wavesurfer-region:before {
  content: "";
  border: 1px solid var(--main-font-color);
  border-radius: 9px;
  width: 100%;
}

.wavesurfer-region:before {
  background-color:#b5b5b526;
}
.wavesurfer-region:nth-child(even):before{
  background-color:#b5b5b566;
}


.wavesurfer-region.wavesurfer-region--hover:before {
  content: "";
  border: 1px solid #ffbe00;
  background-color: #ffbe002A;
  width: 100%;
}

.wavesurfer-region.wavesurfer-region--active:before {
  content: "";
  border: 3px solid #ffbe00;
  background-color: #ffbe0080;
  width: 100%;
}

.wavesurfer-region.wavesurfer-region--active.wavesurfer-region--hover:before {
  background-color: #ffbe0033;
}

.wavesurfer-handle {  
  visibility: hidden;
  height: 100%;
  width: 1px !important;
  /* max-width: 1px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.wavesurfer-handle:before,
.wavesurfer-handle:after { 
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wavesurfer-handle-start:before {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  right: -11px;
  top: 8px;
  content: ' ';
  background-image: url('../assets/svg/right-and-left-arrows.svg');
  background-size: contain;
  background-color: transparent;
  height: 23px;
  width: 23px;
}

.wavesurfer-handle-start:after { 
  position: absolute;
  content: '\e813';
  background: #010101;
  height: 19px;
  width: 19px;
  left: 6px;
  bottom: 5px;
  border-radius: 2px;
  line-height: 19px;
  font-size: 15px;
}

.wavesurfer-handle-end:before {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  right: -10px;
  top: 8px;
  content: ' ';
  background-image: url('../assets/svg/right-and-left-arrows.svg');
  background-size: contain;
  background-color: transparent;
  height: 23px;
  width: 23px;
}

.wavesurfer-handle-end:after {
  position: absolute;
  content: '\e815';
  background: #010101;
  height: 19px;
  width: 19px;
  right: 6px;
  bottom: 5px;
  border-radius: 2px;
  line-height: 19px;
  font-size: 15px;
}

.wavesurfer-handle-start {
  margin-left: -1px;
  right: 100%;
  top: 0px;
}
.wavesurfer-handle-end {
  left: 100%;
  top: 0px;
}