.video {
  flex: 0 1 100%;
}

.video .video-react{
  padding-top: 56.25% !important;
}

.video--overlap-control {
  padding-bottom: 30px;
}

.video--overlap-control :not(.video-react-fullscreen) .video-react-control-bar {
  display: flex;
  position: relative;
}