.team-tab {
  display: flex;
  justify-content: center;
  background-color: var(--main-bg-color);
  color: var(--main-ac-color);
  font-size: 18px;
  font-weight: 100;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 5px;
  outline: none;
}

.team-tab--disabled {
  background-color: var(--main-font-color);
  color: var(--main-font-color-w);
}

.team-tab__name,
.team-tab__action {
  display: flex;
  flex: 1 1;
  flex-flow: row;
}

.team-tab__usage {
  width: 74px;
  text-align: right;
}

.team-tab__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border: 1px solid var(--main-font-color);
  border-radius: 4px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-bg-color);
  outline: none;
  cursor: pointer;
  margin: 2px 0px 2px 9px;
}

.team-tab__button--disabled {
  color: var(--main-bg-color);
  background: var(--main-font-color);
  border: 1px solid var(--main-bg-color);
}

.team-tab__button--hidden {
  visibility: hidden;
  display: none;
}