.loading-bar {
  position: relative;
  flex: 1;
  margin: 0 12px;
}

.loading-bar__task {
  top: -16px;
  text-align: center;
  width: 100%;
  color: var(--main-font-color);
  font-size: xx-small;
  position: absolute;
  word-break: break-all;
}

.loading-bar__task:after {
  content: attr(data-task);
  display: block;
  text-align: center;
  color: var(--main-ac-color);
  margin-top: 3px;
  font-weight: 100;
  letter-spacing: 1px;
}

.loading-bar__loader {
  display: flex;
  flex-flow: column nowrap;
  z-index: 21;
  text-align: center;
  color: black;
  font-size: 10px;
  width: 100%;
  align-items: center;
  position: relative;
}
.loading-bar__percent {
  color: var(--main-font-color);
  font-size: x-small;
  position: absolute;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.loading-bar__progress {
  display: flex;
  position: relative;
  height: 14px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--main-font-color);
  font-size: 23px;
  overflow: hidden;
}

.loading-bar__filler {
  background: var(--hover-bg-color);
  height: 100%;
  border-radius: inherit;
  -webkit-transition: width .2s ease-in;
  transition: width .2s ease-in;
}

.loading-bar__ready {
  color: var(--main-ac-color);
  text-align: center;
  font-weight: 100;
  font-size: 14px;
}

.loading-bar__cancel {
  color: var(--main-font-color-w);
  font-size: xx-small;
  font-weight: 100;
  letter-spacing: 1px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: Transparent;
  background-repeat:no-repeat;
  cursor:pointer;
  overflow: hidden;
  border-radius: 50px;  
  padding: 0 5px;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
