.project {
  display: flex;
  align-self: flex-start;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 660px;
  min-height: 500px;
  max-height: 650px;
}

.project__icon {
  cursor: pointer;
}

.project__delete,
.project__download {
  color: var(--main-font-color);
  background-color: transparent;
  font-size: 19px;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project__loading {  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.project__spinner {
  font-size: 30px;
  color: var(--main-font-color-w);
}

.project__spinner--small {
  font-size: 15px;
}

/* .project__report-range {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px 5px;
} */

.project__text-button {
  position: relative;
  padding: 2px 12px;
  background: var(--main-fg-color);
  color: var(--main-font-color);
  font-size: 10px;
  border-radius: 6px;
  font-weight: 100;
  outline: none;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.project__action-button {
  width: 18px;
  height: 18px;
  font-size: 11px;
  border: 1px solid var(--main-font-color);
  border-radius: 4px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-fg-color);
  outline: none;
  cursor: pointer;
  margin: 2px 0px 2px 9px;
}

.project__action-button:disabled {
  border: 1px solid var(--hover-bg-color);
  color: var(--hover-bg-color);
  background: var(--main-bg-color);
}

.project__title,
.project__credit {
  display: flex;
  font-size: 25px;
  font-weight: 100;
  color: var(--main-ac-color);
  align-items: center;
}

.project__date-range {
  display: flex;
  font-size: 11px;
  align-items: center;
  padding: 1px 7px;
}

.project__date-label {
  padding: 0 5px;
  color: var(--main-font-color);
}

.project__date-input {
  font-family: Roboto;
  font-size: 14px;
  background-color: var(--main-fg-color);
  caret-color: var(--main-font-color);
  border: 1px solid var(--main-fg-color);
  color: var(--main-font-color);
  border-radius: 4px;
  padding: 2px 4px;
  width: 94px;
  outline: none;
}

.project__pagination {
  display: flex;
  justify-content: flex-end;
  margin: 9px 4px;
}

.project__total-items {
  color: var(--main-font-color);
  flex: 1 1;
  text-align: left;
}