
.filelist {
  display: flex;
  list-style-type: none;
  flex-direction: column;
}
.filelist__item {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  text-align: start;
  margin: 5px;
}

.filelist__duration:empty {
  display: none;
}

.filelist__duration {
  font-size: 12px;
  font-weight: 200;
  padding: 3px;
  margin: 0px 10px;
  color: var(--main-ac-color);
}

.filelist__duration:after {
  content: "min";
  font-size: 8px;
  font-weight: 300;
  margin-left: 3px;
}

.filelist__label {
  align-self: flex-start;
  font-weight: lighter;
  font-size: 26px;
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  color: var(--main-font-color);
}

.filelist__button {
  width: 25px;
  height: 25px;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 0 10px;
  align-self: center;
  background-color: var(--main-bg-color);
  border: 1px solid var(--main-font-color);
  border-radius: 8px;
  text-align: center;
}

.filelist__icon {
  color:var(--main-font-color);
  font-size: 17px;
}