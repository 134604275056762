.confirm-action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.confirm-action__overlay {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 1;
}
.confirm-action__overlay--active {
  display: flex;
}

.confirm-action__popup {
  display: none;
  position: absolute;
  background-color: var(--hover-fg-color);
  width: 95px;
  height: 16px;
  z-index: 2;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  padding: 0 1px;
}
.confirm-action__popup--active {
  display: flex;
}

.confirm-action__button {
  background: transparent;
  font-size: 10px;
  border-radius: 6px;
  font-weight: 100;
  outline: none;
  cursor: pointer;
}

.confirm-action__button--cancel {
  color: var(--main-font-color-w);
}
.confirm-action__button--accept {
  color: var(--main-ac-color);
}