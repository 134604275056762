.dropzone {
  background-color: rgba(20,20,20,0);
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: 16px;
  padding-bottom: 14px;
  margin-top: -118px;
}

.dropzone--highlight {
  background-color: var(--hover-bg-color);
}

.dropzone__icon {
  opacity: 0.3;
  font-size: 32px;
  margin-right: 10px;
  color: var(--main-font-color);
}

.dropzone__label {
  color: var(--main-ac-color);
  font-size: 2.1em;
}

.dropzone__file-input {
  display: none;
}