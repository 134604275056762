* {
  margin: 0;
  padding: 0;
}
*, *::after, *::before { box-sizing: border-box; }

:root {
  --main-bg-color: #212225;
  --main-fg-color: #333437;
  --main-ac-color: #f5c300;
  --main-font-color: #b7b7b7;
  --main-font-color-w: #f7f7f7;
  --main-font-color-b: #010101;
  --hover-bg-color: #6c6f73;
  --hover-fg-color: #6b6e73;
  --hover-ac-color: #f3d554;

}

.app {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  
  font-family: 'Roboto', sans-serif;
  background-color: var(--main-bg-color);
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 80px;
}

.app__footer {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-flow: column;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  color: var(--main-font-color);
  font-size: 12px;
  margin-top: 33px;
  margin-bottom: -71px;
  width: 100%;
}

.global-tooltip {
  padding: 2px;
  font-size: 9px;
  font-family: Roboto;
}

.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

.button:hover {
  background-color: var(--hover-fg-color);
}

.button:disabled,
.button[disabled]{
  background-color: var(--main-bg-color);
  color: var(--hover-bg-color);
}

.icon-cc:before {
  font-family: Arial, Helvetica, sans-serif !important;
  content: "cc";
  font-weight: 400;
  text-decoration: none;
}