.transcript-segment {
  display: flex;
  flex: 1 100%;
  justify-content: space-between;
  color: var(--main-font-color-w);
}

.transcript-segment__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: start;
  margin-top: 5px;
  margin-bottom: 2px;
  flex: 1 100%;
  padding: 1px 9px;
  border: 1px solid transparent;
}

.transcript-segment__time {
  display: flex;
  padding-top: 5px;
  flex-flow: column nowrap;
  height: 100%;
}

.transcript-segment__start-time,
.transcript-segment__end-time{
  font-weight: 300;
  font-size: 14px;
  color: var(--main-font-color);
  background: transparent;
  border: none;
  width: 88px;
  text-align: center;
}

.transcript-segment__phrase {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  position: relative;
  height: 100%;
}

.transcript-segment__line {
  font-weight: 300;
  font-size: 16px;
  color: var(--main-font-color);
  outline: none;
  line-height: 1.3;
  padding: 5px 10px;
  background-color: transparent;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: none;
  z-index: 1;
  flex: 1 1;
}

.transcript-segment__line-mirror {
  position: absolute;
  font-weight: 300;
  font-size: 16px;
  color: black;
  outline: none;
  line-height: 1.3;
  padding: 5px 10px;
  background-color: transparent;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: none;
  height: calc(100% - 20px);
  width: 100%;
  z-index: 0;
  flex: 1 1 100%;
  /* padding-top: 5px;
  padding-right: 55px;
  padding-bottom: 5px;
  padding-left: 10px; */
}

.transcript-segment__line-mirror .newline{
  font-size: 11px;
  color: rgba(255,255,255,0.1);
  padding-left: 4px;
  padding-top: 5px;
  position: absolute;
}

.transcript-segment__line-mirror .dot{
  color: rgba(255,255,255,0.3);
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  margin-left: -4px;
}

.transcript-segment__line-count {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-flow: row nowrap;
  flex: 1 1;
  justify-self: self-end;
}

.transcript-segment__line-counter {
  border-radius: 7px;
  font-size: 13px;
  font-weight: 300;
  color: var(--main-font-color);
  background: var(--main-bg-color);
  outline: none;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 2px 0px;
  width: 30px;
  text-align: center;
}

.transcript-segment__line-counter--warning {
  color: var(--main-font-color-b);
  background: var(--main-ac-color);
}

.transcript-segment--active .transcript-segment__line-counter{
  background: var(--main-fg-color);
}

.transcript-segment--active .transcript-segment__line-counter--warning{
  background: var(--main-ac-color);
}

.transcript-segment__caption-edit {
  display: none;
  flex: 1 1 100%;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  height: 20px;
  max-height: 20px;
  align-self: flex-end;
}

.trancript-segment__caption-edit--active {
  display: flex;
}

.transcript-segment--active .transcript-segment__caption-edit-button{
  background: var(--main-fg-color);
}

.transcript-segment__caption-edit-button {
  width: 20px;
  height: 20px;
  border-radius: 7px;
  font-size: 7px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-bg-color);
  outline: none;
  /* margin-top: 3px;
  margin-bottom: 3px; */
  margin-left: 6px;
  margin-right: 6px;
  cursor: pointer;
}

.transcript-segment__caption-edit-button img{
  width: 11px;
  height: 11px;
}

.transcript-segment--last-segment .transcript-segment__caption-edit-button {
  z-index: -1;
}

.transcript-segment__action {
  display: none;
  visibility: hidden;
  flex-flow: column;
  min-height: 100%;
  height: 100%;
  justify-content: space-between;
  /* padding-top: 6px; */
}

.transcript-segment__action--active {
  display: flex;
}

.transcript-segment__caption-delete {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-fg-color);
  outline: none;
  margin-top: 5px;
  margin-bottom: 3px;
  margin-left: 6px;
  margin-right: 6px;
  cursor: pointer;
  padding-left: 1px;
  padding-bottom: 1px;
}

.transcript-segment__caption-add {
  width: 20px;
  height: 20px;
  border-radius: 7px;
  font-size: 10px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-fg-color);
  outline: none;
  margin-top: 3px;
  margin-bottom: 0px;
  margin-left: 6px;
  margin-right: 6px;
  cursor: pointer;
  padding-left: 1px;
  padding-bottom: 1px;
}

.transcript-segment:hover .transcript-segment__caption-delete,
.transcript-segment:hover .transcript-segment__caption-add{
  background: var(--main-bg-color);
}

.transcript-segment--active .transcript-segment__caption-delete,
.transcript-segment--active .transcript-segment__caption-add{
  background: var(--main-fg-color) !important;
}

.transcript-segment--active .transcript-segment__action,
.transcript-segment:hover .transcript-segment__action{
  visibility: visible;
}

.transcript-segment__option {
  display: none;
  visibility: hidden;
  flex: 1 100%;  
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.transcript-segment__option--changed {
  visibility: visible;
}

.transcript-segment__button {
  width: max-content;
  border-radius: 7px;
  padding: 1px 7px;
  font-size: 14px;
  font-weight: 100;
  color: var(--main-font-color);
  background: var(--main-fg-color);
  outline: none;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.transcript-segment__button:first-child {
  background-color: var(--main-ac-color);
}

.transcript-segment--playing .transcript-segment__item {
  border: 1px solid var(--main-ac-color);
  border-radius: 6px 6px 6px 6px;
}

/* .transcript-segment--playing.transcript-segment--focused .transcript-segment__item {
  border-radius: 0px 6px 6px 0px;
  border-left: 4px solid var(--main-ac-color);
  border-right: 1px solid var(--main-ac-color);
  border-top: 1px solid var(--main-ac-color);
  border-bottom: 1px solid var(--main-ac-color);
} */

.transcript-segment--active .transcript-segment__item {
  background-color: var(--main-bg-color);
  color: var(--main-font-color);
  border-radius: 6px 6px 6px 6px;
}

/* .transcript-segment--focused .transcript-segment__item { 
  padding-left: 6px;
  border-left: 4px solid var(--main-ac-color);
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
} */

/* .transcript-segment--active .transcript-segment__phrase{
  color: #f8f8f8;
}

.transcript-segment--active .transcript-segment__phrase > div {
  color: #f8f8f8;
} */