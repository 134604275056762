.custom-modal__overlay {
  position: fixed;
  display: flex;
  top: -364px;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
}

.custom-modal {
  display: flex;
  font-family: Roboto;
  flex-flow: column nowrap;
  background-color: var(--main-fg-color);
  padding: 5px;
  border-radius: 5px;
  outline: none;
  width: 300px;
}

.custom-modal__header {
  display: flex;
  background-color: var(--main-bg-color);
  color: var(--main-ac-color);
  font-size: 18px;
  font-weight: 100;
  cursor: default;
  padding: 5px;
  margin: 2px 0 5px;
  text-align: center;
  border: none;
  border-radius: 5px;
  outline: none;
}

.custom-modal__title {
  flex: 1;
}

.custom-modal__close-button {
  width: 17px;
  height: 17px;
  font-size: 10px;
  border: 1px solid var(--main-font-color);
  border-radius: 4px;
  font-weight: 200;
  color: var(--main-font-color);
  background: var(--main-bg-color);
  outline: none;
  cursor: pointer;
  align-self: center;
}

.custom-modal__content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  /* padding: 5px; */
}

.custom-modal__form {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.custom-modal__field {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.custom-modal__input:-webkit-autofill,
.custom-modal__input:-webkit-autofill:hover, 
.custom-modal__input:-webkit-autofill:focus, 
.custom-modal__input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px var(--main-fg-color) inset !important;
  box-shadow: 0 0 0 30px var(--main-fg-color) inset !important;
  -webkit-text-fill-color: var(--main-font-color) !important;
}

.custom-modal__input::placeholder { /* Most modern browsers support this now. */
  color: var(--main-font-color);
  font-weight: 200;
}
.custom-modal__input,
.custom-modal__input:-webkit-autofill {
  font-family: Roboto;
  font-size: 14px;
  background-color: var(--main-bg-color);
  caret-color: var(--main-font-color);
  border: none;
  color: var(--main-font-color);
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  margin: 5px 0px;
  text-align: left;
  flex: 1 1;
  outline: none;
}

.custom-modal__input--large {
  font-size: 25px !important;
  text-align: right !important;
}

.custom-modal__submit-button {
  position: relative;
  padding: 2px 15px;
  background: var(--main-fg-color);
  color: var(--main-font-color);
  font-size: 14px;
  border-radius: 6px;
  font-weight: 100;
  outline: none;
  right: 0;
  cursor: pointer;
  align-self: flex-end;
  width: 71px;
}

/* .custom-modal__select,
.custom-modal__input {
  margin: 2px 0px;
} */

.custom-modal__select .custom-modal__select__control--is-focused,
.custom-modal__select .custom-modal__select__control {
  background-color: var(--main-bg-color);
  border-color: transparent;
  box-shadow: none;
  min-height: 24px;
}

.custom-modal__select__value-container {
  height: 24px;
}

.custom-modal__select__value-container:last-child{
  margin: 0;
  padding-bottom:0;
  padding-top: 0;
}

.custom-modal__select .custom-modal__select__control:hover {
  border-color: transparent;
}

.custom-modal__select__placeholder {
  font-size: 14px;
  color: var(--main-font-color);
}

.custom-modal__select .custom-modal__select__input,
.custom-modal__select .custom-modal__select__single-value {
  caret-color: var(--main-font-color);
  color: var(--main-font-color);
  font-size: 14px;
}
.custom-modal__select .custom-modal__select__indicators {
  padding-right: 42px;
}

.custom-modal__select .custom-modal__select__dropdown-indicator:hover {
  border-color: var(--main-font-color);
}

.custom-modal__select .custom-modal__select__indicators svg{
  height: 23px;
  width: 23px;
  position: absolute;
  right: 5px;
  align-self: center
}

.custom-modal__select .custom-modal__select__indicators .custom-modal__select__indicator-separator{
  visibility: hidden;
}

.custom-modal__select .custom-modal__select__menu {
  background-color: var(--main-bg-color);
  color: var(--main-font-color);
}

.custom-modal__select .custom-modal__select__option--is-focused {
  background-color: var(--main-fg-color);
  color: var(--main-font-color);
}

.custom-modal__select .custom-modal__select__option--is-selected {
  background-color: var(--main-font-color);
  color: var(--main-fg-color);
}