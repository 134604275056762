.loading-screen {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  top: 0;
  left: 0;
  z-index: 20;
  justify-content: center;
  align-items: center;
}

.loading-screen--active {
  display: flex;
}

.loading-screen__loader {
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  z-index: 21;
  text-align: center;
  color: black;
  font-size: 2.4em;
  width: 200px;
  align-items: center;
}

.loading-screen__progress {
  color: var(--main-ac-color);
  font-size: 100px;
}

.loading-screen__bar {
  position: relative;
  height: 35px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--main-bg-color);
  font-size: 23px;
  overflow: hidden;
}

.loading-screen__task {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  color: var(--main-font-color);
  mix-blend-mode: color-dodge;
}

.loading-screen__task:after {
  content: attr(data-task);
  display: block;
  text-align: center;
  color: var(--main-font-color);
  margin-top: 3px;
  font-weight: 100;
  letter-spacing: 1px;
}

.loading-screen__filler {
  background: var(--main-fg-color);
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
